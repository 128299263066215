import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import DescriptionIcon from '@mui/icons-material/Description';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import DiamondIcon from '@mui/icons-material/Diamond';
import DownloadIcon from '@mui/icons-material/Download';
import EventIcon from '@mui/icons-material/Event';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import ManageAccounts from '@mui/icons-material/ManageAccounts';
import PaymentsIcon from '@mui/icons-material/Payments';
import PeopleIcon from '@mui/icons-material/People';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import StarsIcon from '@mui/icons-material/Stars';
import WorkIcon from '@mui/icons-material/Work';
import { useMediaQuery } from '@mui/material';
import Divider from '@mui/material/Divider';
import MuiTooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import { useCallback, useRef } from 'react';

import { useSections } from 'api';
import { t } from 'components/formatted-message';
import { CrownIcon } from 'components/icons/crown-icon';
import { useCurrentProject } from 'context/current-project';
import { useFeatures } from 'context/feature-flag-provider';
import { SECTIONS } from 'utils/constants';
import { makeStyles } from 'utils/make-styles';

import { ProjectSearch } from './project-search';
import { SidebarItem } from './sidebar-item';

const useStyles = makeStyles()(
  theme => ({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    divider: {
      margin: theme.spacing(4, 3, 2),
    },
    searchIcon: {
      color: theme.palette.text.secondary,
      height: 64,
      width: 40,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      '&:hover': {
        background: theme.palette.action.hover,
      },
      '&.isClosed': {
        display: 'none',
      },
    },
    search: {
      position: 'initial',
      opacity: 1,
      '&.isClosed': {
        position: 'absolute',
        opacity: 0,
        zIndex: -1,
      },
    },
  }),
  { name: 'bo-Sidebar' }
);

function transformSections(sections) {
  return sections.reduce((sectionsMap, section) => ({ ...sectionsMap, [section.key]: true }), {});
}

export function Sidebar({ isOpen, toggleIsOpen }) {
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const searchInputref = useRef();
  const { classes, cx } = useStyles();

  const [project] = useCurrentProject();
  const { data: sections } = useSections({
    select: transformSections,
  });

  const onSearchIconClick = useCallback(() => {
    searchInputref.current.focus();
    toggleIsOpen(true);
  }, [toggleIsOpen]);

  const features = useFeatures();

  return (
    <div className={classes.root}>
      {sections[SECTIONS.STATISTIC] && (
        <SidebarItem
          href="/statistics"
          icon={InsertChartIcon}
          isOpen={isOpen}
          section={SECTIONS.STATISTIC}
        />
      )}
      {sections[SECTIONS.PLAYERS_HUB] && (
        <SidebarItem
          href="/players-hub"
          icon={ManageAccounts}
          isOpen={isOpen}
          section={SECTIONS.PLAYERS_HUB}
        />
      )}
      {sections[SECTIONS.JACKPOT_HITS] && (
        <SidebarItem
          href="/jackpot-hits"
          icon={DiamondIcon}
          isOpen={isOpen}
          section={SECTIONS.JACKPOT_HITS}
        />
      )}
      {sections[SECTIONS.PROJECTS] && (
        <SidebarItem href="/projects" icon={WorkIcon} isOpen={isOpen} section={SECTIONS.PROJECTS} />
      )}
      {sections[SECTIONS.AUDIT] && (
        <SidebarItem
          href="/audit"
          icon={DescriptionIcon}
          isOpen={isOpen}
          section={SECTIONS.AUDIT}
        />
      )}
      {sections[SECTIONS.EXPORTS] && (
        <SidebarItem
          href="/exports"
          icon={DownloadIcon}
          isOpen={isOpen}
          section={SECTIONS.EXPORTS}
        />
      )}
      <Divider classes={{ root: classes.divider }} />

      <ProjectSearch
        className={cx(classes.search, !isOpen && 'isClosed')}
        inputRef={searchInputref}
      />

      <MuiTooltip
        // tooltip should only exist for desktop closed sidebar
        enterTouchDelay={0}
        leaveTouchDelay={15000}
        placement="right"
        title={!isDesktop || isOpen ? '' : t('sections.search.mini')}
        arrow
        disableFocusListener>
        <div
          className={cx(classes.searchIcon, isOpen && 'isClosed')}
          role="button"
          tabIndex={-1}
          onClick={onSearchIconClick}
          onKeyPress={onSearchIconClick}>
          <SearchIcon />
        </div>
      </MuiTooltip>
      {sections[SECTIONS.CMS] && (
        <SidebarItem
          href={`/${project?.key}/cms/admin`}
          icon={DeveloperBoardIcon}
          isOpen={isOpen}
          locale=""
          section={SECTIONS.CMS}
          isExternal
        />
      )}
      {sections[SECTIONS.BONUSES] && (
        <SidebarItem
          href={`/${project?.key}/bonuses`}
          icon={StarsIcon}
          isOpen={isOpen}
          section={SECTIONS.BONUSES}
        />
      )}
      <SidebarItem
        href={`/${project?.key}/bonus-statistics`}
        icon={StackedLineChartIcon}
        isOpen={isOpen}
        section={SECTIONS.BONUS_STATISTIC}
      />
      {sections[SECTIONS.ROLES] && (
        <SidebarItem
          href="/roles"
          icon={AccountTreeIcon}
          isOpen={isOpen}
          section={SECTIONS.ROLES}
        />
      )}
      {sections[SECTIONS.BONUSES] &&
        process.env.ENVIRONMENT_NAME !== 'mga_b2b' &&
        process.env.ENVIRONMENT_NAME !== 'zaf' &&
        process.env.ENVIRONMENT_NAME !== 's2br-bra' &&
        process.env.ENVIRONMENT_NAME !== 'turf-zaf' && (
          <SidebarItem
            href={`/${project?.key}/jackpot-teams`}
            icon={PeopleIcon}
            isOpen={isOpen}
            section={SECTIONS.JACKPOT_TEAMS}
          />
        )}
      {sections[SECTIONS.PLAYERS] && process.env.ENVIRONMENT_NAME !== 'mga_b2b' && (
        <SidebarItem
          href={`/${project?.key}/players`}
          icon={AccountCircleIcon}
          isOpen={isOpen}
          section={SECTIONS.PLAYERS}
        />
      )}
      {sections[SECTIONS.BETS] && (
        <SidebarItem
          href={`/${project?.key}/bets`}
          icon={PaymentsIcon}
          isOpen={isOpen}
          section={SECTIONS.BETS}
        />
      )}
      <SidebarItem
        href={`/${project?.key}/events`}
        icon={EventIcon}
        isOpen={isOpen}
        section={SECTIONS.EVENTS}
      />
      {sections[SECTIONS.VIP_BETS_AUTO_ACCEPT] &&
        features?.vip_bet_auto_accept_backoffice_enabled && (
          <SidebarItem
            href={`/${project?.key}/vip-bets-auto-accept`}
            icon={props => <CrownIcon {...props} />}
            isOpen={isOpen}
            section={SECTIONS.VIP_BETS_AUTO_ACCEPT}
          />
        )}
      {sections[SECTIONS.PROJECT_SETTINGS] && (
        <SidebarItem
          href={`/${project?.key}/project-settings`}
          icon={SettingsIcon}
          isOpen={isOpen}
          section={SECTIONS.PROJECT_SETTINGS}
        />
      )}
    </div>
  );
}

Sidebar.propTypes = {
  toggleIsOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
